<template>
    <div class="row header-top-var">
        <div class="col-lg-3 col-md-3 col-sm-3 display-flex">
            <div class="logo-area default">
                <router-link to="/">
                    <img src="/assets/img/logo/logo1.png" />
                </router-link>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 display-flex">
            <!--<search-box></search-box>-->
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 display-flex">
            <div class="default display-flex">
                <router-link class="dk-btn dk-btn-info" to="/verification">
                    Verification Check
                </router-link>
            </div>
        </div>
    </div>
</template>


<script>
    import searchBox from '../menuComponents/searchBox.vue';
    export default {
        components: {
            'search-box': searchBox,
        }
    }
</script>

<style scoped>
    a:hover, a:focus {
        color: #fff;
    }
    .dk-btn {
        font-size: 17px;
        padding: 11px 15px;
    }
        .dk-btn-info {
        overflow: hidden;
    }
    .display-flex {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-top-var {
        padding: 10px;
    }

    header.main-header .logo-area a img {
        height: 50px;
        margin-top: 2px;
    }

    .text-router-static {
        text-align: center;
        padding: 10px;
    }

        .text-router-static .router-static {
            font-size: 16px;
            font-weight: bold;
            color: #00538a;
        }

    .all-width {
        display: inline-block;
        width: 75%;
    }

    .order .shopping_bag-16, .signOut .media-1_button-power {
        text-align: right !important;
        font-size: 18px !important;
        color: #333 !important;
        opacity: .6 !important;
        padding-left: 10px;
        padding-right: 15px;
        vertical-align: middle;
    }

    .order, .signOut {
        text-align: right !important;
    }

    .user-login ul li {
        cursor: pointer;
    }
</style>