<template>
    <div class="div-home">
        <div v-if="showLoader" class="wrapper-child">
            <!--<img src="/assets/img/loader/loaderImageHome.svg" />
            <p>please wait...</p>-->
        </div>
        <div v-else class="wrapper-parent">

            <responsive-header-component></responsive-header-component>
            <!-- responsive-header -->

            <div class="wrapper default">

                <!-- header -->
                <header class="main-header default">
                    <!--<headerTop-component></headerTop-component>-->
                    <!-- responsive-header -->
                    <div class="container">
                        <header-component></header-component>
                    </div>
                    <menu-component></menu-component>
                </header>
                <!-- header -->


                <main class="main default">
                    <div class="container">
                        <div class="row">
                            <section id="main-slider" class="carousel slide carousel-fade card" data-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <a class="d-block" style="padding: 25px;">
                                            <img src="https://file.abppharmaceuticals.com/Sliders/main1.jpg" class="d-block w-100" alt="">
                                        </a>
                                    </div>
                                    <div class="carousel-item">
                                        <a class="d-block" style="padding: 25px;">
                                            <img src="https://file.abppharmaceuticals.com/Sliders/main2.jpg" class="d-block w-100" alt="">
                                        </a>
                                    </div>
                                </div>
                            </section>
                            <!--<section id="main-slider" class="carousel slide carousel-fade card" data-ride="carousel" style="margin-top:10px; margin-bottom:10px;">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <a class="d-block">
                                            <img src="https://file.oxopharmaceuticals.com/Sliders/main2.jpg" class="d-block w-100" alt="">
                                        </a>
                                    </div>
                                </div>
                            </section>-->
                            <div class="company-main col-12 col-lg-12 order-1 order-lg-2">
                                <widget-product :sectionNumber="0"></widget-product>
                            </div>
                            <div class="verification-main col-12 col-lg-12 order-1 order-lg-2" style="margin-top:10px; margin-bottom:10px;">
                                <verification-Product></verification-Product>
                            </div>
                            <div class="company-main col-12 col-lg-12 order-1 order-lg-2">
                                <widget-product :sectionNumber="1"></widget-product>
                            </div>
                            <div class="pro-main no-padding-right no-padding-left col-12 col-lg-12 order-1 order-lg-2">
                                <our-company></our-company>
                            </div>
                            <div class="company-main col-12 col-lg-12 order-1 order-lg-2">
                                <widget-product :sectionNumber="2"></widget-product>
                            </div>
                            <div class="advantages-main no-padding-right no-padding-left col-12 col-lg-12 order-1 order-lg-2">
                                <advantages-company></advantages-company>
                            </div>
                        </div>
                        <!--<special-brands></special-brands>-->
                    </div>
                </main>
                <footer-component></footer-component>
                <!--<footer-fixnav-component></footer-fixnav-component>-->
            </div>
        </div>



    </div>
</template>

<script>
    import sliders from './sliders.vue';
    import verificationProduct from './verificationProduct.vue';
    import ourCompany from './ourCompany.vue';
    import advantagesDetail from './advantagesDetail.vue';
    import advantagesCompany from './advantagesCompany.vue';
    import widgetProduct from './widgetProduct.vue';
    import { initialSite } from "../../module/initialSite";


    export default {
        data() {
            return {
                banner: 0,
                showLoader: false,
            }
        },
        components: {
            'sliders': sliders,
            'widget-product': widgetProduct,
            'our-company': ourCompany,
            'advantages-company': advantagesCompany,
            'advantages-detail': advantagesDetail,
            'verification-Product': verificationProduct
        },
        computed: {
            banners: function () {
                return this.$store.getters.readBanners;
            }
        },
        beforeMount: function () {
            createPageTitle('Home');
            initialSite(this);
        }
    }
</script>

<style scoped>
    .verification-main {
        padding: 10px 0 10px 0;
        /*background-color: #000000;*/
        /*background-image: linear-gradient(141deg, #9fb8ad 0%,#083140 70%,#db301f 51% )*/
    }

    .tp-bging-main {
        position: relative;
    }

    .pro-main {
        background-color: #fff;
    }

    .slider-main {
        background-color: #fff;
    }

    .tp-bging-main, .company-main, .slider-main {
        padding-left: 0;
        padding-right: 0;
    }

    .logo-main-navbar {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mg-top-10 {
        background: #f2f2f2;
    }

    .all-nav {
        background: #f2f2f2;
        border-bottom: 4px solid #FFD700;
    }

    header.main-header .logo-area a img {
        height: 50px;
        float: right;
        margin-top: 2px;
    }

    @media only screen and (min-width: 0px) and (max-width: 640px) {
        .verification-main {
            padding: 20px 0 30px 0;
        }
    }

    @media only screen and (min-width:650px) and (max-width: 850px) {
        .verification-main {
            padding: 20px 0 30px 0;
        }
    }

    @media only screen and (min-width:851px) and (max-width: 991px) {
        .verification-main {
            padding: 20px 0 30px 0;
        }
    }
</style>