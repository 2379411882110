<template>
    <div class="content-main no-padding-right no-padding-left   col-md-12 col-sm-12 col-xs-12">
        <div class="row">
            <div class="page-title div-img-header">
                <div class="container">
                    <div class="row">
                        <div class="div-title-main col col-xs-12">
                            <h2>About Us</h2>
                            <ol class="breadcrumb">
                                <li><router-link to="/">Home</router-link></li>
                                <li>About Us</li>
                            </ol>
                        </div>
                    </div> <!-- end row -->
                </div>
            </div>
            <div class="content">
                <div class="div-content pb-20">
                    <div class="row">
                        <div class="section-about col-xs-12 col-lg-5 col-md-12">
                            <h1>ABP PHARMACEUTICALS</h1>
                            <p>
                                We are one of the best pharmaceutical companies in the whole European Union and have received several certificates from the World Medicines Organization. Our goal is to make the best medicines with the best quality and the right dosage. We also use the best global materials. Our pharmaceutical factory and company is located in Bern, Switzerland. For any questions and communication with us, use the contact us page. We wish you health and success.
                            </p>
                        </div>
                        <div class="pt-60 col-lg-7 col-xs-12">
                            <div class="offer-pic">
                                <img src="/assets/img/inline-page/offer-pic.jpg" alt="ABP PHARMACEUTICALS" style="float: right;" />
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        beforeMount: function () {
            createPageTitle('AboutUs');
        }
    }
</script>

<style scoped>
   

    .div-img-header {
        background: url("/assets/img/header/AboutUs.jpg");
        background-size: cover;
        min-height: 550px;
        background-repeat: no-repeat;
        position: relative;
        width: 100%;
    }
        .div-img-header .row{
            background-color: transparent !important;
        }
        .page-title .breadcrumb {
            text-align: center;
        }

    .page-title .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    .page-title ol {
        color: #fff;
    }

    .page-title h2 {
        font-size: 48px;
        font-size: 3.2rem;
        font-weight: 800;
        margin: 1em 0 0.23em;
    }

    .page-title h2 {
        color: #fff;
    }

    .page-title .breadcrumb li a {
        color: #fff;
    }

    .page-title .breadcrumb li {
        font-size: 18px;
        font-size: 1.2rem;
        color: #FFD700;
        margin-right: 5px;
    }
    .page-title .breadcrumb > li + li::before {
        font-family: "FontAwesome";
        content: "\f105";
        color: #fff;
        margin-right: 5px;
    }


    .section-about h1 {
        font-size: 30px;
    }

    .page-title .breadcrumb li a:hover {
        color: #FFD700;
    }

   
   

    .hr {
        margin-top: 45px;
        margin-bottom: 30px;
    }
    /*محتویات Css  قسمت مدیریت صفحات استاتیک*/

    .content-main .content {
        width: 100%;
        margin: 10px 0%;
        padding: 80px;
    }

        .content-main .content .title {
            font-size: 20px;
            padding-left: 20px;
            padding-top: 20px;
        }

        .content-main .content .div-content p {
       
            line-height: 2;
            font-size: 15px;
        }
    /***ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo*/
    .box-tabs-tab a {
        padding: 14px 20px;
        position: relative;
        color: #6f6f6f;
        display: block;
        line-height: 27px;
        height: 100%;
        width: 100%;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .none-border::after {
        border-bottom: unset !important;
    }

    .box-tabs-tab {
        border-right: unset;
        position: relative;
        margin-right: 50px;
        margin-left: 50px;
    }

    .box-tabs > ul {
        list-style: none;
        border: unset;
        background-color: unset;
        font-size: 17px;
        font-size: 1.214rem;
        line-height: 1.294;
        letter-spacing: -.4px;
        width: 100%;
        padding: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

   

    .box-tabs-tab a {
        border: 1px solid #0a548b;
    }

        .box-tabs-tab a.active {
            background-color: #0a548b;
            color: #fff;
            border: 1px solid #0a548b;
            margin-left: -1px;
        }

    .param-p {
     
        line-height: 2;
    }
    .div-title-main {
        padding-left: 80px;
        margin-top: 0px;
    }
    @media only screen and (min-width: 0px) and (max-width: 640px) {

        .content-main .content {
            width: 100%;
            padding: 20px;
        }
    .box-tabs > ul {
            padding-left: 20px;
        }

    .section-about h1 {
            font-size: 19px;
            font-weight: bold;
        }

    .div-title-main {
            padding-left: 20px;
            margin-top: -45px;
        }

    .param-title span, .param-title {
            font-size: 14px;
        }

    .param-p {
            font-size: 12px;
        }

  

    .content-main .content .title {
                font-size: 17px;
            }

    .content-main .content .div-content p {
                line-height: 2;
                font-size: 14px;
            }

    

    .address-title span {
            font-size: 16px;
        }

   

    .content-main .content .title {
                font-size: 17px;
            }
    .content-main .content .div-content p {
                text-align: justify;
                line-height: 2;
                font-size: 12px;
            }
    }
    /*محتویات Css  قسمت مدیریت صفحات استاتیک*/
</style>