<template>
    <div>
    <footer class="main-footer div-home">
        <div class="back-to-top">
            <div style="display:none;">
                Back
            </div>
           
        </div>
        <div class="container">
            <footer-widgets></footer-widgets>
        </div>
        <footer-copyright></footer-copyright>
       
    </footer>
   </div>
</template>

<script>
    import footerCopyright from './footerCopyright.vue'
    import footerInfo from './footerInfo.vue'
    import footerWidgets from './footerWidgets.vue'
    export default {
        components: {
            'footer-copyright': footerCopyright,
            'footer-info': footerInfo,
            'footer-widgets': footerWidgets
        }
    }
</script>

<style scoped>

    footer.main-footer .back-to-top {
        font-size: 20px;

        margin-bottom: unset;
    }
    .container {
        background-color: #CC9E3E;
        padding-bottom: 20px;
    }
</style>