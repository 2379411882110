<template>
    <div>
        <div v-if="isShowLoader" class="wrapper-child">
            <!--<img src="/assets/img/loader/loaderImageHome.svg" />
            <p>Please wait...</p>-->
        </div>
        <div v-else class="wrapper-parent">
            <!-- responsive-header -->

            <responsive-header-component></responsive-header-component>
            <!-- responsive-header -->
            <div class="wrapper default">
                <!-- header -->
                <header class="main-header default">
                    <div class="container">
                        <header-component></header-component>
                    </div>
                    <menu-component></menu-component>
                </header>
                <!-- header -->
                <main class="content-main default">
                    <div style="background-color:#fff" class="container">
                        <router-view></router-view>
                    </div>
                </main>
                <footer-component></footer-component>
                <!--<footer-fixnav-component></footer-fixnav-component>-->
            </div>
        </div>

    </div>
</template>

<script>
    
    import { initialSite } from "../../module/initialSite";
    export default {
        data() {
            return {
                isShowLoader: false
            }
        },
        components: {
        },
        beforeMount: function () {
            initialSite(this);
        },
    }
</script>

<style scoped>
    @media only screen and (min-width: 640px) and (max-width: 1077px) {
        .content-main {
            margin-top: 50px;
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 640px) {
        .content-main {
            margin-top: 45px;
        }
    }
    .card {
        box-shadow: unset;
    }
    .wrapper-child {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
</style>