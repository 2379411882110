<template>
    <div class="footer-widgets">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
                <div class="widget-menu widget card">
                    <header class="card-header">
                        <h3 class="card-title">ABP Pharmaceuticals</h3>
                    </header>
                    <div>
                        <p class="text-p">
                            We offer our products with the best quality<br />
                            Available doses are real and of the highest quality
                        </p>
                    </div>
                </div>
                <div class="logo-area default">
                    <router-link to="/">
                        <img src="/assets/img/logo/logo2.png" style="width: 70% !important;" />
                    </router-link>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
                <div class=" widget-menu widget card">
                    <header class="card-header">
                        <h3 class="card-title">Menu</h3>
                    </header>
                    <ul class="footer-menu">
                        <li>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>
                            <router-link to="/aboutUs">About Us</router-link>
                        </li>
                        <li>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>
                            <router-link to="/contactUs">Contact Us </router-link>
                        </li>
                        <li>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>
                            <router-link to="/filter">Products </router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
                <div class="widget-menu widget card" v-if="baseInfo != null">
                    <header class="card-header">
                        <h3 class="card-title">Contact Information</h3>
                    </header>
                    <p class="text-p">
                        <img src="/assets/img/SWITZERLAND.png" style="border-radius: 0px !important;" />
                        <br />
                        SWITZERLAND BERN
                    </p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
                <div class="widget-menu widget card" v-if="baseInfo != null">
                    <header class="card-header">
                        <h3 class="card-title">Payment Information</h3>
                    </header>
                    <div class="logo-area default">
                        <router-link to="/">
                            <img src="/assets/img/cards.png" style="border-radius: 0px !important;" />
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            baseInfo: function () {
                return this.$store.getters.readBaseInfo;
            }
        }
    }
</script>

<style scoped>
    .footer-social a .fa {
        padding-right: 3px !important;
    }

    .footer-social a {
        padding-right: 10px;
    }

    .text-center {
        text-align: center;
    }

    .footer-menu li span.title {
        color: black;
        font-weight: bold;
        font-size: 17px;
    }

    .footer-menu li .fa {
        font-size: 18px;
        vertical-align: middle;
        padding-right: 8px;
        color: black;
    }

    .footer-menu li a {
        color: black;
        font-size: 15px;
    }

    .text-p {
        color: black;
        line-height: 1.9;
    }

    .div-image {
        width: 16px;
        height: 16px;
        display: inline-block;
    }

    .img-aparat {
        width: 16px;
        border-radius: 10px;
        margin-bottom: 8px;
    }

    footer.main-footer .footer-widgets .socials .footer-social {
        width: 100%;
        background-color: #c67672;
    }


        footer.main-footer .footer-widgets .socials .footer-social a.margin {
            margin: 0 25px;
        }

    @media only screen and (min-width: 991px) and (max-width: 1165px) {
        footer.main-footer .footer-widgets .socials .footer-social a.margin {
        }

        footer.main-footer .footer-widgets .socials .footer-social a.a-block {
            display: block !important;
        }

        footer.main-footer .footer-widgets .socials .footer-social {
            height: 100%;
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 640px) {
        .footer-menu li .fa {
            font-size: 16px;
        }

        .footer-menu li span.title {
            font-size: 15px;
        }

        .footer-menu li a {
            font-size: 14px;
        }

        .text-center {
            text-align: right !important;
        }
    }
</style>