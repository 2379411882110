<template>
    <div class="copyright" v-if="baseInfo != null">
        <div class="container">
            <p>
                The use of the content of {{baseInfo.ShopName}} Online Store is forbidden for non-commercial purposes only. All rights reserved by {{baseInfo.ShopName}} Company.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            baseInfo: function () {
                return this.$store.getters.readBaseInfo;
            }
        }
    }
</script>

<style scoped>
    @media only screen and (min-width: 0px) and (max-width: 640px) {
        .copyright p {
            text-align: justify;
            padding-bottom: 40px;
        }
    }
</style>