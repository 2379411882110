<template>
    <div>
        <div v-if="isSendingDataToServer" class="wrapper-child">
            <div class="div-img">
                <!--<img src="/assets/img/loader/loaderImageHome.svg" />
                <p class="div-img-p">please wait...</p>-->
            </div>
        </div>
        <div v-if="showLoader" class="wrapper-child">
            <div class="div-img">
                <!--<img src="/assets/img/loader/loaderImageHome.svg" />
                <p class="div-img-p">please wait...</p>-->
            </div>
        </div>
        <div v-else>
            <!-- responsive-header -->
            <!--<headerTop-component></headerTop-component>-->
            <responsive-header-component></responsive-header-component>
            <!-- responsive-header -->

            <div class="wrapper default">

                <!-- header -->
                <header class="main-header default">
                    <div class="container">
                        <header-component></header-component>
                    </div>
                    <menu-component></menu-component>
                </header>
                <!-- header -->
                <!----------------------------------------------------------------------------------------->
                <!-- main -->
                <main class="single-product default">
                    <div class="container mb-35" v-if="subGroupInfo != null && subGroupInfo.Description != null && subGroupInfo.Description != ''">
                        <div class="row padding-row-progroup">
                            <div class="section-about col-lg-5 col-md-12">
                                <h2>{{ subGroupInfo.Title  }}</h2>
                                <p v-text="subGroupInfo.Description">

                                </p>
                            </div>
                            <div class="col-lg-7 col-lg-7 d-none d-md-block d-md-none">
                                <div class="offer-pic">

                                    <img :src="subGroupInfo.PicPath" :alt="subGroupInfo.Title" v-if="subGroupInfo.PicPath != null && subGroupInfo.PicPath != ''" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container all-tag-pro">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 order-2" v-if="products.length > 0 || isSendingDataToServer">


                                <!--<div class="breadcrumb-section default">
                                    <ul class="breadcrumb-list">
                                        <li>
                                            <router-link class="name-store" tag="a" to="/">
                                                <span>Online Store</span>
                                            </router-link>
                                        </li>
                                        <li><span>{{ breadcrumbName }}</span></li>
                                    </ul>
                                </div>-->

                                <div class="listing default">
                                    <!--<div class="listing-counter"> Product found {{ filteredCount }}</div>-->

                                    <div class="tab-content default text-center">
                                        <div class="tab-pane active" id="related" role="tabpanel" aria-expanded="true">
                                            <div class="container no-padding-right">
                                                <ul class="row listing-items">
                                                    <li v-for="product in products" class="col-xl-3 col-lg-4 col-md-6 col-12 padding">
                                                        <div class="product-box">
                                                            <a class="product-box-img" :href="'https://abppharmaceuticals.com/product/' + product.Id + '/' + product.Name.replace(/\s+/g, '-')">
                                                                <img :src="product.PicPath" @error="imageLoadOnError" alt="">
                                                            </a>
                                                            <div class="product-box-content">
                                                                <div class="product-box-content-row">
                                                                    <div class="product-box-title">
                                                                        <a :href="'https://abppharmaceuticals.com/product/' + product.Id + '/' + product.Name.replace(/\s+/g, '-')">
                                                                            {{ product.Name }}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!--<div class="product-box-content">
                                                                <div class="product-box-content-row">
                                                                    <div class="product-box-title">
                                                                        <span>Price: 200 €</span>
                                                                    </div>
                                                                </div>
                                                            </div>-->
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="productPaging" class="pager default text-center" style="display:none;"></div>
                                </div>
                            </div>
                            <div class="container text-center col-12 col-sm-12 col-md-12 col-lg-12 order-2" v-else="">
                                <no-result-found></no-result-found>
                            </div>
                        </div>
                    </div>
                </main>
                <!-- main -->
                <!------------------------------------------------------------------------------------------>
                <footer-component></footer-component>
                <!--footer-fixnav-component></footer-fixnav-component>-->
            </div>
        </div>

    </div>
</template>
<script>
    import { httpPromise } from "../../module/httpUtility";
    import { initialSite } from "../../module/initialSite";
    import noResultFound from './noResultFound.vue';

    export default {
        data() {
            return {
                showLoader: false,
                filteredCount: 0,
                products: [],
                isSendingDataToServer: false,
                breadcrumbName: '',
                subGroupInfo: ''
            }
        },
        watch: {
            $route: function (to, from) {
                this.sendToServer();
            },
        },
        beforeMount() {
            initialSite(this);
            this.sendToServer();
        },
        components: {
            'no-result-found': noResultFound,
        },

        methods: {
            createQueryStringParameter(page) {
                var query = {};
                var allowToAppendQueryString = true;

                if (!IsNullOrEmptyString(this.$route.query.SubGroupId)) {
                    query.SubGroupId = this.$route.query.SubGroupId;
                    allowToAppendQueryString = false;
                    if (!IsNullOrEmptyString(this.$route.query.SubGroupName)) {
                        query.SubGroupName = this.$route.query.SubGroupName;
                    }
                }
                if (allowToAppendQueryString) {
                    if (!IsNullOrEmptyString(this.$route.query.TagId)) {
                        query.TagId = this.$route.query.TagId;
                        allowToAppendQueryString = false;
                        if (!IsNullOrEmptyString(this.$route.query.TagName)) {
                            query.TagName = this.$route.query.TagName;
                        }
                    }
                }
                if (allowToAppendQueryString) {
                    if (!IsNullOrEmptyString(this.$route.query.SearchValue)) {
                        query.SearchValue = this.$route.query.SearchValue;
                        allowToAppendQueryString = false;
                    }
                }
                if (allowToAppendQueryString) {
                    if (!IsNullOrEmptyString(this.$route.query.BrandCode)) {
                        query.BrandCode = this.$route.query.BrandCode;
                        allowToAppendQueryString = false;
                        if (!IsNullOrEmptyString(this.$route.query.BrandName)) {
                            query.BrandName = this.$route.query.BrandName;
                        }
                    }
                }
                if (!IsNullOrEmptyString(page))
                    query.Page = page;

                this.$router.push({ name: 'filter', query: query });
            },

            createPaging(totalDataCount, pageNumber) {
                var createQueryStringParameterDelegate = this.createQueryStringParameter;
                $(function () {
                    try {
                        $('#productPaging').pagination('destroy');
                    } catch  { }
                    var dataSource = [];
                    for (var i = 1; i <= totalDataCount; i++)
                        dataSource.push(i);
                    if (dataSource != null && dataSource.length > 0) {
                        var container = $('#productPaging').pagination({
                            dataSource: dataSource,
                            pageSize: 12,
                            pageNumber: pageNumber,
                            classPrefix: 'pager',
                            ulClassName: 'pager-items',
                            activeClassName: 'is-active',
                        });
                        container.addHook('afterPaging', function () {
                            createQueryStringParameterDelegate(container.pagination('getSelectedPageNum'));
                        });
                    } 
                });
            },
            breadcrumbCreator() {
                if (!IsNullOrEmptyString(this.$route.query.SubGroupName)) {
                    this.breadcrumbName = 'Group search ' + this.$route.query.SubGroupName;
                } else {
                    if (!IsNullOrEmptyString(this.$route.query.TagName)) {
                        this.breadcrumbName = 'Search The Tag ' + this.$route.query.TagName;
                    } else {
                        if (!IsNullOrEmptyString(this.$route.query.SearchValue)) {
                            this.breadcrumbName = 'Phrase Search  ' + '"' + this.$route.query.SearchValue + '"';
                        } else {
                            if (!IsNullOrEmptyString(this.$route.query.BrandName)) {
                                this.breadcrumbName = 'Brand Search  ' + '"' + this.$route.query.BrandName + '"';
                            }
                        }
                    }
                }
                this.breadcrumbName = this.breadcrumbName.replace(/-/g, ' ');
                createPageTitle(this.breadcrumbName);
            },
            scrollTop() {
                $(function () {
                    $('html, body').animate({ scrollTop: 0 }, 800, 'easeInExpo');
                });
            },
            imageLoadOnError(event) {
                event.target.src = "/assets/img/no-image-available.png";
            },
            sendToServer() {
                this.isSendingDataToServer = true;
                httpPromise(this, '/v1/ProductManagement/Filters/Read', 'get', this.$route.query,
                    response => {
                        this.filteredCount = response.data.Value.TotalCount;
                        this.subGroupInfo = response.data.Value.SubGroupInfo;
                        this.products = response.data.Value.FilteredProducts;
                        this.breadcrumbCreator();
                        this.createPaging(response.data.Value.TotalCount, response.data.Value.Page);
                        this.products = response.data.Value.FilteredProducts;
                        this.scrollTop();
                        this.isSendingDataToServer = false;
                    },
                    response => {
                        this.isSendingDataToServer = false;
                    }
                );
            },
            dowbloadCatalog(docPath) {
                toastr.remove();
                if (IsNullOrEmptyString(docPath)) {
                    toastr.error('No product catalogs available for this product');
                } else {
                    window.open(docPath, '_blank');
                    window.open(docPath);
                }
            }
        }
    }
</script>

<style scoped>
    .dk-btn-danger {
        overflow: hidden;
        color: #fff !important;
    }

        .dk-btn-danger:focus {
            color: #fff !important;
        }

    .listing-header::before {
        content: "";
    }

    .dk-btn {
        font-size: 16px;
    }

    .product-box-row {
        text-align: center;
    }

    .parent-btn {
        width: 100%;
    }


    .dk-btn::before {
        width: 28%;
        height: 195%;
    }

    main .single-product {
        margin-top: 10px;
    }

    .mb-35 {
        margin-bottom: 35px;
    }

    .padding-row-progroup {
        padding: 80px;
    }

    .section-about p {
        font-size: 16px;
        line-height: 1.9;
        padding: 25px;
    }

    
    /*::after {
        content: "";
        width: 465px;
        height: 465px;*/
        /*border: 2px solid #FFD700;*/
        /*position: absolute;
        left: 150px;
        top: -22px;
    }*/

    .offer-pic img {
        float: right;
        position: relative;
        z-index: 2;
    }

    .section-title-s3 h2, .section-title-s4 h2 {
        font-size: 30px;
        font-size: 2rem;
        line-height: 1.33em;
        margin: 0.13em 0 0;
        padding-bottom: 0.3em;
        position: relative;
    }


    .dk-btn > i {
        font-size: 20px;
        position: absolute;
        left: 18px;
        top: 21px;
        color: rgba(255, 255, 255, 0.7);
        font-style: normal;
    }


    .dl-catalog {
        text-align: center;
    }



    .pro-tag .img-tag-pro img {
        width: 200px;
        height: 200px;
        overflow: hidden;
    }

    .pro-tag .img-tag-pro {
        height: 180px;
    }

    .all-tag-pro {
        margin: 25px 0 25px 0;
    }

    .pro-tag {
        text-align: center;
        padding: 10px;
    }

        .pro-tag a {
            font-size: 15px;
        }

            .pro-tag a:hover {
                color: #777;
            }

    .single-product {
        background-color: #000;
    }

    .title-group-pro h1 {
        font-size: 32px;
        font-weight: bold;
        padding: 20px 0 0 20px;
    }

    .text-group-pro p {
        padding: 20px 0 0 20px;
        text-align: justify;
        line-height: 1.9;
        font-size: 18px;
    }

    .img-group-pro img {
        padding: 20px 0 0 20px;
        width: 600px;
        height: 400px;
    }

    @media only screen and (min-width: 0px) and (max-width: 640px) {
        main.single-product {
            margin-top: 68px;
        }

        .section-about h2 {
            font-size: 25px;
        }

        .section-about p {
            font-size: 14px;
            line-height: 1.8;
            padding: 2px;
        }

        .padding-row-progroup {
            padding: 10px;
        }

        .pro-tag a {
            font-size: 13px;
        }

        .title-group-pro h1 {
            font-size: 25px;
        }

        .dk-btn > i {
            font-size: 20px;
            position: absolute;
            left: 12px;
            top: 16px;
            color: rgba(255, 255, 255, 0.7);
            font-style: normal;
        }

        .text-group-pro p {
            padding: 0px 10px 0 10px;
            text-align: justify;
            line-height: 1.9;
            font-size: 14px;
        }

        .img-group-pro img {
            padding: 0;
            width: 600px;
            height: unset;
        }

        .dk-btn {
            padding: 13px 39px;
        }
    }
</style>