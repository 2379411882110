<template>
    <section class="main-product" v-if="promoSectionProduct != '' && promoSectionProduct[sectionNumber] != null">
        <div class="widget widget-product card">
            <div class="product-carousel owl-carousel owl-theme">
                <div class="item  discount-box" v-for="(product,index) in promoSectionProduct[sectionNumber].PromProducts" >
                    <a @click.prevent="redirectUrl(product.Link)" :href="product.Link">
                        <img :src="product.PicPath" class="img-fluid" :alt="product.Title" :title="product.Title"/>
                    </a>
                    <h2 class="btn-redirect post-title">
                        <a @click.prevent="redirectUrl(product.Link)" :href="product.Link" :alt="product.Title">{{ product.Title }} </a>
                    </h2>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
    export default {
        props: ["sectionNumber"],
        computed: {
            promoSectionProduct() {
                return this.$store.getters.readPromoSectionProducts;
            }
        },
        methods: {
            imageLoadOnError(event) {
                event.target.src = "/assets/img/no-image-available.png";
            },
            redirectUrl(url) {
                if (IsNullOrEmptyString(url)) {

                } else {
                    window.open(url, '_blank');
                    window.open(url);
                }
            }
        }
    }
</script>
<style scoped>

    .card {
        box-shadow: unset;
    }

    .main-product {
        background-color: #000 !important;
    }

    .widget-product .product-carousel .item .post-title, .brand-slider .product-carousel .item .post-title {
        font-size: 20px;
        display: block;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 64px;
        line-height: 40px;
        height: unset;
        font-weight: bold;
    }

    .text-pro {
        color: #00538a;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;
        display: block;
        padding-bottom: 25px;
    }

    @media only screen and (min-width: 0px) and (max-width: 640px) {

  
    }
</style>