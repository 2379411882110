<template>
    <nav class="main-menu">
        <div class="container">
            <ul class="list float-right">
                <li class="list-item list-item-has-children mega-menu mega-menu-col-5">
                    <router-link tag="a" class="nav-link" to="/">Home</router-link>
                </li>
                <li class="list-item list-item-has-children mega-menu mega-menu-col-5">
                    <router-link class="nav-link" to="/AboutUs">About Us</router-link>
                </li>
                <li class="list-item list-item-has-children mega-menu mega-menu-col-5">
                    <router-link class="nav-link" to="/filter">Products</router-link>
                </li>
                <!--<li class="list-item list-item-has-children mega-menu mega-menu-col-5">
                    <router-link class="nav-link" to="/faq">FAQ</router-link>
                </li>-->
                <li class="list-item list-item-has-children mega-menu mega-menu-col-5">
                    <router-link class="nav-link" to="/ContactUs">Contact Us</router-link>
                </li>
                <li class="list-item list-item-has-children mega-menu mega-menu-col-5">
                    <router-link class="nav-link" to="/verification">Check</router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    export default {
        computed: {
            groups: function () {
                return this.$store.getters.readGroups;
            }
        }, 
        methods: {
            imageLoadOnError(event) {
                event.target.src = "/assets/img/no-image-available.png";
            },
        }
    }
</script>

<style scoped>
 .main-menu .list .list-item {
        display: inline-block;
    }
    .main-menu .list > .list-item > a::before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #FFD700;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .subgroup-nav-title:hover {
        color: #145e95 !important;
        transition: all .3s;
    }
    .subgroup-nav-title {
        color: #979797 !important;
        font-weight: bold !important;
        font-size: 13px !important;
        transition: all .3s;
    }
    .group-nav-link {
        color: #0a548b;
        font-weight: bolder !important;
        font-size: 14px !important;
    }
    header.main-header .main-menu {
        width: 100%;
        float: right;
        margin-top: 0;
        border-bottom:unset;
    }
    .list-item a{
    color: #957777;
    }
  
        header.main-header .main-menu .list > .list-item:hover > a {
            box-shadow: unset;
        }
    header.main-header .main-menu .list .list-item.mega-menu > .sub-menu {
        min-width: 230px;
        width: 230px;
    }
    .nav-link{
        cursor:pointer;
    }
</style>