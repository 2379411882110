<template>
    <div class="content-main no-padding-right no-padding-left  col-md-12 col-sm-12 col-xs-12">
        <div class="row">
            <div class="page-title div-img-header">
                <div class="container">
                    <div class="row">
                        <div class="div-title-main col col-xs-12">
                            <h2>Pharmaceutical </h2>
                            <ol class="breadcrumb">
                                <li><router-link to="/">Home</router-link></li>
                                <li>Pharmaceutical</li>
                            </ol>
                        </div>
                    </div> <!-- end row -->
                </div>
            </div>
            <div class="content">

                <div class="div-content">
                    <p>

                    </p>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
    export default {
        beforeMount: function () {
            createPageTitle('Pharmaceutical');
        }
    }
</script>
<style scoped>
    .div-title-main {
        padding-left: 150px;
    }

    .div-img-header {
        background: url("/assets/img/header/page-title.jpg") center center/cover no-repeat local;
        height: 550px;
        position: relative;
        width: 100%;
    }

    .page-title .breadcrumb {
        text-align: center;
    }

    .page-title .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    .page-title ol {
        color: #fff;
    }

    .page-title h2 {
        font-size: 48px;
        font-size: 3.2rem;
        font-weight: 800;
        margin: 1em 0 0.23em;
    }

    .page-title h2 {
        color: #fff;
    }

    .page-title .breadcrumb li a {
        color: #fff;
    }

    .page-title .breadcrumb li {
        font-size: 18px;
        font-size: 1.2rem;
        color: #FFD700;
        margin-right: 5px;
    }

    .page-title::before {
        content: "";
        background-color: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .page-title .breadcrumb > li + li::before {
        font-family: "FontAwesome";
        content: "\f105";
        color: #fff;
        margin-right: 5px;
    }

    .page-title .breadcrumb li a:hover {
        color: #FFD700;
    }

    ul {
        padding-left: 10px;
        line-height: 1.8;
    }

    .div-content ul li {
        list-style: disc;
    }

    .div-content-h3 {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
        padding-left: 3px;
        color: #666;
    }

    .address-text p {
        color: #777;
        font-size: 14px;
        padding-left: 7px;
    }

    .address-title i {
        font-size: 20px;
        color: cadetblue;
        vertical-align: baseline;
    }

    .address-title span {
        font-size: 18px;
        font-weight: bold;
        color: #555;
    }

    .btn-info {
        background-color: #2CA8FF;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 17px;
        padding: 8px 35px;
    }

    .btn-div {
        text-align: center;
    }
    /*محتویات Css  قسمت مدیریت صفحات استاتیک*/
    .input-textarea {
        padding: 11px 11px 11px 15px;
    }

    .content-main .content {
        width: 100%;
        margin: 20px 12%;
        padding: 20px;
    }

        .content-main .content .title {
            font-size: 20px;
            padding-left: 20px;
            padding-top: 20px;
        }

        .content-main .content .div-content p {
            text-align: justify;
            line-height: 2;
            font-size: 15px;
        }

    @media only screen and (min-width: 0px) and (max-width: 640px) {
        .div-title-main {
            padding-left: 20px;
        }

        .div-content ul li {
            font-size: 12px;
        }

        .div-content-h3 {
            font-size: 14px;
        }

        .address-title span {
            font-size: 16px;
        }

        .content-main .content {
            margin: 40px 0%;
        }

            .content-main .content .title {
                font-size: 17px;
            }

            .content-main .content .div-content p {
                text-align: justify;
                line-height: 2;
                font-size: 12px;
            }
    }
    /*محتویات Css  قسمت مدیریت صفحات استاتیک*/
</style>