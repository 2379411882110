<template>
    <div>
        <div v-if="showProductLoader" class="wrapper-child">
            <!--<img src="/assets/img/loader/loaderImageHome.svg" />
            <p>please wait...</p>-->
        </div>
        <div v-if="showLoader" class="wrapper-child">
            <!--<img src="/assets/img/loader/loaderImageHome.svg" />
            <p>please wait...</p>-->
        </div>
        <div v-else>
            <!-- responsive-header -->
            <!--<headerTop-component></headerTop-component>-->
            <responsive-header-component></responsive-header-component>
            <!-- responsive-header -->

            <div class="wrapper default">

                <!-- header -->
                <header class="main-header default">
                    <div class="container">
                        <header-component></header-component>
                    </div>
                    <menu-component></menu-component>

                </header>
                <!-- header -->
                <!----------------------------------------------------------------------------------------->
                <!-- main -->
                <main class="single-product default" v-if="productInfo != null" style="background-color: #fff;">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <nav>
                                    <ul class="breadcrumb">
                                        <li>
                                            <router-link to="/"><span>Home</span></router-link>
                                        </li>
                                        <li>
                                            <a><span>{{productInfo.GroupTitle}}</span></a>
                                        </li>
                                        <li>
                                            <a><span>{{productInfo.SubGroupTitle}}</span></a>
                                        </li>
                                        <li>
                                            <a><span>{{productInfo.Name}} </span></a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <article class="product">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6 col-sm-12"  style="margin: 10px; border-radius: 25px; box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.2);">
                                            <div class="product-gallery default">
                                                <img id="img-product-zoom" class="zoom-img" :src="productInfo.PicPath" :data-zoom-image="productInfo.PicPath" width="411" @error="imageLoadOnError" />
                                                <div id="gallery_01f" style="width:500px;float:left;">
                                                    <ul class="gallery-items">
                                                        <li>
                                                            <a href="#" class="elevatezoom-gallery" data-update="" :data-image="productInfo.PicPath" :data-zoom-image="productInfo.PicPath">
                                                                <img :src="productInfo.PicPath" width="100" @error="imageLoadOnError" />
                                                            </a>
                                                        </li>
                                                        <li v-if="productInfo.ProductPics != null" v-for="(pic,index) in productInfo.ProductPics">
                                                            <a href="#" :class="['elevatezoom-gallery',(index == 0)? 'active' : '']" data-update="" :data-image="pic.FilePath" :data-zoom-image="pic.FilePath">
                                                                <img :src="pic.FilePath" width="100" @error="imageLoadOnError" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-7 col-md-6 col-sm-12"  style="margin: 10px; border-radius: 25px; box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.2);">
                                            <div class="product-title default">
                                                <h1>
                                                    {{productInfo.Name}}
                                                </h1>
                                            </div>
                                            <div class="product-title default">
                                                <p>
                                                    {{productInfo.Description}}
                                                </p>
                                            </div>
                                            <div class="product-directory default">
                                                <ul>
                                                    <li>
                                                        <span class="txt-titlr-d">Brand</span> :
                                                        <span class="product-brand-title">{{productInfo.BrandTitle}}</span>
                                                    </li>

                                                </ul>
                                            </div>
                                            <!--<div class="product-directory default">
                                                <ul>

                                                    <li>
                                                        <span class="txt-titlr-d">‌Group </span> :
                                                        <router-link to="#" class="btn-link-border">
                                                            {{productInfo.GroupTitle}}
                                                        </router-link>
                                                    </li>
                                                    <li>
                                                        <span class="txt-titlr-d">Sub Group</span> :
                                                        <router-link :to="{name: 'filter' , query:{ SubGroupId: productInfo.SubGroupId , SubGroupName: productInfo.SubGroupTitle.replace(/\s+/g, '-')  }}" class="btn-link-border">
                                                            {{productInfo.SubGroupTitle}}
                                                        </router-link>
                                                    </li>

                                                </ul>
                                            </div>-->
                                            <!--<div class="product-guarantee tages-generate default">
                                                <span class="txt-titlr-d">Tag</span> :
                                                <ul>
                                                    <li v-for="ProductTags in productInfo.ProductTags">
                                                        <router-link :to="{ name: 'filter' , query: { TagId: ProductTags.Id , TagName: ProductTags.Title.replace(/\s+/g, '-') }}">{{ ProductTags.Title }}</router-link>
                                                    </li>

                                                </ul>
                                            </div>-->

                                        </div>
                                        <!--<div class="col-lg-4 col-md-6 col-sm-12 center-breakpoint">
                                            <div class="product-params default">
                                                <ul v-for="propertyGroup in productInfo.ProductPropertyGroups">
                                                    <li v-for="PropertyValue in  propertyGroup.ProductPropertyValue">
                                                        <span>{{PropertyValue.PropertyTitle}} :</span>
                                                        <span>{{PropertyValue.PropertyValue}}</span>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>-->
                                    </div>
                                </article>
                            </div>
                        </div>
                        <!--<div class="row">
                            <div class="container">
                                <div class="col-12 default no-padding">
                                    <div class="product-tabs default">
                                        <div class="box-tabs default">
                                            <ul class="nav" role="tablist">
                                                <li class="box-tabs-tab">
                                                    <a class="active" data-toggle="tab" href="#description" role="tab" aria-expanded="false">
                                                        <i class="now-ui-icons ui-2_settings-90"></i> Description
                                                    </a>
                                                </li>
                                                <li class="box-tabs-tab">
                                                    <a data-toggle="tab" href="#video" role="tab" aria-expanded="false">
                                                        <i class="now-ui-icons tech_tv"></i> Videos
                                                    </a>
                                                </li>
                                            </ul>
                                            <div class="card-body default">-->
                                                <!-- Tab panes -->
                                                <!--<div class="tab-content">
                                                    <div class="tab-pane params " id="video" role="tabpanel" aria-expanded="false">
                                                        <div class="row section-video-row" v-if="productInfo.ProductMedias != null && productInfo.ProductMedias.length > 0">
                                                            <section v-for="productMedia in productInfo.ProductMedias" class="col-lg-3 col-sm-6 col-md-6 col-xs-12 section-video">
                                                                <iframe :src="productMedia.URL" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
                                                            </section>
                                                        </div>

                                                        <div v-else class="row">
                                                            <section class="empty-media col-lg-12">
                                                                <p class="text-center">No video has been recorded for this product</p>
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane form-comment active" id="description" role="tabpanel" aria-expanded="false">
                                                        <article>
                                                            <div v-if="productInfo.Description != null " class="text-p-description" v-html="productInfo.Description"> </div>
                                                        </article>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 default no-padding">
                                    <div class="product-tabs default">
                                        <div class="box-tabs default">
                                            <ul class="nav" role="tablist"></ul>
                                            <div class="card-body default">-->
                                                <!-- Tab panes -->
                                                <!--<div class="tab-content">


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 ">
                                <div class="widget widget-product card">
                                    <div class="owl-stage-outer">
                                        <h5>Similar products</h5>
                                        <div class="product-carousel owl-carousel owl-item">
                                            <div class="item" v-for="similarProduct in  productInfo.SimilarProducts">
                                                <router-link tag="a" :to="{ name: 'product' , params: { productId: similarProduct.Id , productName: similarProduct.Name.replace(/\s+/g, '-') }}" class="pointer">
                                                    <img :src="similarProduct.PicPath" class="img-fluid" :alt="similarProduct.Name" @error="imageLoadOnError" />
                                                </router-link>
                                                <h2 class="post-title">
                                                    <router-link tag="a" :to="{ name: 'product' , params: { productId: similarProduct.Id , productName: similarProduct.Name.replace(/\s+/g, '-') }}">{{ similarProduct.Name }}</router-link>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>-->
                    </div>
                </main>
                <!-- main -->
                <!------------------------------------------------------------------------------------------>
                <footer-component></footer-component>
                <!--<footer-fixnav-component></footer-fixnav-component>-->
            </div>
        </div>

    </div>
</template>


<script>
    import { initialSite } from "../../module/initialSite";
    import { httpPromise } from "../../module/httpUtility";


    export default {
        data() {
            return {
                productInfo: null,
                showLoader: false,
                showProductLoader: true
            }
        },
        watch: {
            $route: function (to, from) {
                this.sendToServer();
            },
        },
        beforeMount: function () {
            initialSite(this);
            this.sendToServer();
        },
        methods: {
            sendToServer() {
                this.showProductLoader = true;
                window.scrollTo(0, 0);
                httpPromise(this, '/v1/ProductManagement/ProductInformation/Read', 'get', { ProductId: this.$route.params.productId },
                    response => {
                        this.productInfo = response.data.Value;
                        this.showProductLoader = false;
                        createPageTitle(response.data.Value.Name);
                        initialJs();
                    },
                    response => {
                        this.showProductLoader = false;
                    }
                );
            },
            imageLoadOnError(event) {
                event.target.src = "/assets/img/no-image-available.png";
            },
            dowbloadCatalog(docPath) {
                toastr.remove();
                if (IsNullOrEmptyString(docPath)) {
                    toastr.error('No product catalogs available for this product');
                } else {
                    window.open(docPath, '_blank');
                    window.open(docPath);
                }
            }
        },
        beforeDestroy() {
            $(function () {
                $('#img-product-zoom-ZoomContainer').remove();
            });
        },
    }
</script>

<style scoped>

    .widget-product .product-carousel .item .post-title, .brand-slider .product-carousel .item .post-title {
        font-size: 16px;
        display: block;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 64px;
        line-height: 32px;
        height: unset;
        font-weight: bold;
    }

    .text-pro {
        color: #00538a;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;
        display: block;
        padding-bottom: 25px;
    }



    .product-params ul li::before {
        left: 5px;
        right: unset;
    }

    .title-proprty, .text-property {
        display: inline-block;
    }

    .product-params ul li {
        direction: ltr;
        text-align: left;
        padding: 2px 0 2px 40px;
        font-size: 16px;
    }

    .iframe-div {
        width: 320px;
        height: 240px;
    }

    .product-add .parent-btn a {
        color: #fff !important;
    }

    .params .params-list li {
        padding: 10px;
        background-color: #f1f0f0;
        margin-right: 90px;
    }

    .params section {
        float: none;
        overflow: hidden;
    }

    .params h3.params-title {
        display: block;
        background-color: #e6dede;
        padding: 13px;
    }

    .params .params-title::before {
        right: 2px;
        bottom: 20px;
    }

    .product-guaranteed::after, .product-guaranteed::before {
        content: none;
    }

    .discount {
        display: inline-block;
        position: relative;
    }

        .discount::after {
            content: '';
            width: 100%;
            position: absolute;
            left: -6px;
            border-top: 1px solid #c6c6c6;
            -webkit-transform: rotate(-30deg);
            transform: rotate(0deg);
            top: -1px;
        }

    .discountvalue {
        position: absolute;
        top: -19px;
        right: 10px;
        font-size: 20px;
        color: #c6c6c6;
    }

    .params section {
        float: none;
    }

    .params .params-title {
        top: 15px;
    }

    .tages-generate ul li {
        float: left;
        margin-right: 10px;
        margin-top: 10px;
    }

    .tages-generate ul {
        margin-top: 10px;
    }

        .tages-generate ul li a {
            background-color: #cccccc;
            padding: 10px 15px 10px 15px;
            color: #fff;
            border-radius: 17px;
            -webkit-transition: all .3s;
            transition: all .3s;
            font-size: 16px;
            font-weight: bold;
        }

        .tages-generate ul li:hover a {
            transition: all .3s;
            color: #777;
        }

    .pointer {
        cursor: pointer;
    }

    @media only screen and (min-width: 0px) and (max-width: 640px) {

        .tages-generate ul li a {
            padding: 8px 13px 8px 13px;
            font-size: 15px;
        }

        .section-video-row {
            text-align: center;
        }

        main.single-product {
            margin-top: 90px;
        }

        .text-p-description p {
            font-size: 13px;
            line-height: 1.8;
        }

        .empty-media p {
            font-size: 13px;
        }

        .dk-btn {
            padding: 12px 39px;
        }

        .params .params-list li {
            padding: 15px;
            background-color: #dddd;
            margin-right: 10px;
        }

        .iframe-div {
            width: 270px;
            height: 200px;
        }

        .widget-product .product-carousel .item .post-title {
            font-size: 13px;
        }

        .product-params ul li {
            direction: ltr;
            text-align: left;
            padding: 2px 0 2px 40px;
            font-size: 13px;
        }

            .product-params ul li:before {
                content: "";
                width: 5px;
                height: 5px;
                background: #ccc;
                vertical-align: middle;
                margin-left: 28px;
                border-radius: 50%;
                position: absolute;
                top: 12px;
                right: -14px;
            }
    }
</style>